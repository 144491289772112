.it-footer-main {
  .it-brand-wrapper a {
    .icon {
      height: auto;
      margin-bottom: 0;
    }

    .it-brand-text,
    .it-brand-text h2,
    .it-brand-text h3 {
      color: $white;
    }
  }
}
