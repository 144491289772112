.block.iconBlocks {
  .background-image {
    &::after {
      background-color: $tertiary;
    }
  }
  .transparent .block-header {
    .title,
    .description {
      color: $black;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      a {
        color: $black;
      }
      a:hover {
        color: $primary-light;
      }
    }
  }
  .card .iconblock-icon .icon {
    color: $secondary;
  }
}
