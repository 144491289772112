.it-header-center-wrapper {
  .it-header-center-content-wrapper {
    .it-brand-wrapper a .icon {
      height: auto;
      margin-bottom: 0;
    }

    .it-search-wrapper a.rounded-icon {
      background-color: $secondary;

      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        border-radius: 50%;
        padding: 0.3rem;
        box-sizing: content-box;
      }

      &:hover {
        background-color: $button-hover-secondary-color;
      }
    }

    .it-right-zone {
      color: $black;

      .it-socials {
        ul {
          li {
            a:hover {
              .icon {
                fill: $button-hover-primary-color;
              }
            }

            .icon {
              fill: $secondary;
            }
          }
        }
      }
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  }
}
