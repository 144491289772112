$primary-h: 0 !default;
$primary-s: 0 !default;
$primary-b: 0 !default;

$primary: #000000;
$primary-light: lighten($primary, 30);
$secondary: #b9122c;
$tertiary: #d7d8d9;

$header-bg-color: #fff;
$header-center-bg-color: $header-bg-color;

$headings-color: $primary;

$black: #000000;
$light: $tertiary;

$site-link-color: $secondary;
$link-hover-color: $primary;
$button-hover-primary-color: $primary-light;
$button-hover-secondary-color: lighten($secondary, 8);
$input-label-color: $primary;
