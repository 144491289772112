.squares-image-template {
  .box {
    &:before {
      background-color: rgba($primary-a12, 0.85);
    }

    &:focus,
    &:hover {
      &:before {
        background-color: $primary-a12;
      }
    }
  }
}
