.card {
  .card-body .category-top,
  .categoryicon-top .text,
  .etichetta,
  .card-body .card-text {
    color: $primary-light;

    .icon {
      fill: $secondary;
    }
  }

  .flag-icon {
    background-color: $secondary;
  }
}
a.read-more .icon {
  fill: $secondary;
}
