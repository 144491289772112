.card-slide-text-template {
  .grid {
    .bg-img {
      background-color: $primary-a12;
    }
  }
  .box {
    .category {
      background-color: $secondary;
      &::after {
        border-top-color: $secondary;
      }
    }
  }
}
