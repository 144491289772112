.bandi-in-evidence {
  .title {
    a.bando-title {
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .read-more a.read-more {
    color: $primary;
  }
}
