.gdpr-privacy-content {
  .btn.btn-primary {
    background-color: $secondary;

    &:hover {
      background-color: $button-hover-secondary-color;
    }
  }

  .btn.btn-black {
    &:hover {
      background-color: $button-hover-primary-color;
    }
  }
}
