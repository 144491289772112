.navbar {
  .navbar-collapsable {
    .navbar-nav li a.nav-link {
      .icon.megamenu-toggle-icon {
        fill: #fff;
      }
    }

    .menu-wrapper .it-brand-wrapper h2,
    .menu-wrapper .it-brand-wrapper h3 {
      color: $primary;
    }
  }

  &.has-megamenu li.nav-item.active .dropdown a.nav-link {
    border-color: $primary;
  }

  button.custom-navbar-toggler svg.icon {
    fill: $primary;
  }
}

@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-navbar-wrapper {
    background-color: $secondary;
  }
}
