// Links
a {
  color: $secondary;
}

//Background
.bg-primary {
  background-color: $primary-a12 !important;
}

// Buttons
a.btn,
.btn {
  &.btn-primary:hover {
    background-color: $button-hover-primary-color;
  }

  &.btn-tertiary {
    background-color: $secondary;

    &:hover {
      background-color: $button-hover-secondary-color;
    }
  }
}
.search-container .btn-tertiary {
  color: #fff;
}

// DraftJs
.draftjs-buttons {
  a:hover {
    background-color: $button-hover-primary-color;
  }
}

.callout-bg {
  background-color: $tertiary;
}

// Pagination
.pagination .page-item .page-link {
  color: $primary-light;
}

// Page
.link-list-wrapper.menu-link-list h3:after {
  background-color: $secondary;
}
