.it-brand-text {
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $black;
  }
}

.it-brand-wrapper a figure.icon {
  margin-bottom: 0;
}
