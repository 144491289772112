.breadcrumb-container {
  .breadcrumb {
    li.breadcrumb-item {
      a {
        color: $secondary;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}
